import { render, staticRenderFns } from "./wxLogin.vue?vue&type=template&id=252b7b8b&scoped=true&"
import script from "./wxLogin.vue?vue&type=script&lang=js&"
export * from "./wxLogin.vue?vue&type=script&lang=js&"
import style0 from "./wxLogin.vue?vue&type=style&index=0&id=252b7b8b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252b7b8b",
  null
  
)

export default component.exports